import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Pagination,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { htmlToSlate } from "../../utils";
import { getFullApiUrl } from "../../config";
import Header from "../Header";
import { LoadingPage } from "../LoadingPage";
import { ErrorPage } from "../ErrorPage";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PodcasterEpisodesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [podcastData, setPodcastData] = useState(null);
  const [videoData, setVideoData] = useState({
    videos: [],
    numberOfEpisodes: 0,
  });
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [videoPage, setVideoPage] = useState(1);
  const podcastId = location.state.podcastId;

  useEffect(() => {
    console.log(`tabValue: ${tabValue}`);
    const fetchData = async () => {
      try {
        setLoading(true);
        if (tabValue === 0) {
          const response = await axios.get(
            getFullApiUrl("/api/v1/subscribe/get_podcast_in_json"),
            {
              params: {
                start: (page - 1) * 10,
                end: page * 10,
                podcast: podcastId,
              },
            }
          );

          setPodcastData({
            podcast: JSON.parse(response.data.podcast),
            episodes: JSON.parse(response.data.episodes),
            numberOfEpisodes: response.data.number_of_episodes,
          });
        } else if (tabValue === 1) {
          const videoResponse = await axios.get(
            getFullApiUrl("/api/v1/subscribe/get_videos"),
            {
              params: {
                podcast: podcastId,
                start: (videoPage - 1) * 10,
                end: videoPage * 10,
              },
            }
          );
          setVideoData(videoResponse.data);
          console.log(videoResponse.data);
        }
      } catch (err) {
        console.error("Error fetching RSS data:", err);
        setError("Failed to load podcast data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [page, videoPage, podcastId, tabValue]);

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleVideoPageChange = async (event, value) => {
    setVideoPage(value);
  };

  const handleVideoCardClick = (item) => {
    console.log(item);
    navigate("/podcaster/editor", {
      state: {
        initialTitle: item.Title || "",
        initialValue: htmlToSlate(item.Content),
        initialPaidStatus: item.IsPaid,
        initialAudio: item.VideoDirectory,
        initialPublishDate: item.PublishedAt,
        initialPodcastId: podcastId,
        isVideo: true,
      },
    });
  };

  const handleCardClick = (item) => {
    console.log(item);
    console.log(htmlToSlate(item.Content));
    navigate("/podcaster/editor", {
      state: {
        initialTitle: item.Title || "",
        initialValue: htmlToSlate(item.Content),
        initialPaidStatus: item.IsPaid,
        initialAudio: item.AudioFilename,
        initialPublishDate: item.PublishedAt,
        initialDuration: item.Duration,
        initialEpisodeId: item.ID,
        initialPodcastId: podcastId,
        isVideo: false,
      },
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    console.log(`newValue: ${newValue}`);
  };

  const VideoTab = () => {
    return (
      <>
        <Grid container spacing={3} justifyContent="center" sx={{ mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/podcaster/editor", {
                state: {
                  initialPodcastId: podcastId,
                  isVideo: true,
                },
              });
            }}
            startIcon={<AddIcon />}
          >
            创建新视频
          </Button>
          {videoData &&
            Array.from(videoData.videos).map((item, index) => (
              <Grid item xs={12} key={index}>
                <CardActionArea onClick={() => handleVideoCardClick(item)}>
                  <Card sx={{ display: "flex", height: "30%" }} elevation={3}>
                    <CardContent sx={{ flex: 1 }}>
                      <Typography component="h2" variant="h5">
                        {item.Title || "No title"}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        {new Date(item.PublishedAt).toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
        </Grid>
        <Pagination
          sx={{ mt: 5 }}
          count={Math.ceil((videoData.number_of_videos || 0) / 10)}
          page={videoPage}
          onChange={handleVideoPageChange}
        />
      </>
    );
  };

  const PodcastTab = () => {
    return (
      <>
        <Grid container spacing={3} justifyContent="center" sx={{ mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/podcaster/editor", {
                state: {
                  initialPodcastId: podcastId,
                  isVideo: false,
                },
              });
            }}
            startIcon={<AddIcon />}
          >
            创建新播客
          </Button>
          {podcastData &&
            Array.from(podcastData.episodes).map((item, index) => (
              <Grid item xs={12} key={index}>
                <CardActionArea onClick={() => handleCardClick(item)}>
                  <Card sx={{ display: "flex", height: "30%" }} elevation={3}>
                    <CardContent sx={{ flex: 1 }}>
                      <Typography component="h2" variant="h5">
                        {item.Title || "No title"}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        {new Date(item.PublishedAt).toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
        </Grid>
        <Pagination
          sx={{ mt: 5 }}
          count={Math.ceil((podcastData.numberOfEpisodes || 0) / 10)}
          page={page}
          onChange={handlePageChange}
        />
      </>
    );
  };

  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      maxWidth="md"
    >
      <Header podcast={podcastData.podcast} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="播客" {...a11yProps(0)} />
          <Tab label="视频" {...a11yProps(1)} />
        </Tabs>
      </Box>
      {tabValue === 0 && <PodcastTab podcastData={podcastData} />}
      {tabValue === 1 && <VideoTab videoData={videoData} />}
    </Container>
  );
};

export default PodcasterEpisodesPage;
