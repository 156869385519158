import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";
import { useTheme } from "@emotion/react";
import {
  Avatar,
  Chip,
  Divider,
  Link,
  Paper,
  useMediaQuery,
} from "@mui/material";
import PodcastAudioPlayer from "./PodcastAudioPlayer";
import { getFullApiUrl, ITEMS_PER_PAGE, podcastId } from "../../config";
import Header from "../Header";
import RssTextField from "./RssTextField";
import { getPodcastSession, renderContent } from "../../utils";
import { LoadingPage } from "../LoadingPage";
import { ErrorPage } from "../ErrorPage";

const PODCAST_ID = podcastId;

const PodcastBlog = () => {
  const [podcastData, setPodcastData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [expandedEpisodes, setExpandedEpisodes] = useState({});
  const [podcastSession, setPodcastSession] = useState("");
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchPodcastData = async () => {
      try {
        setLoading(true);
        const start = (page - 1) * ITEMS_PER_PAGE;
        const end = start + ITEMS_PER_PAGE;
        const response = await axios.get(
          getFullApiUrl("/api/v1/subscribe/get_podcast_in_json"),
          {
            params: { start, end, podcast: PODCAST_ID },
          }
        );
        setPodcastData(response.data);
        setTotalPages(
          Math.ceil(response.data.number_of_episodes / ITEMS_PER_PAGE)
        );
        let podcastSession = "";
        try {
          podcastSession = getPodcastSession() || "";
        } catch (error) {
          console.error("Error accessing localStorage:", error);
        }
        setPodcastSession(podcastSession);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching podcast data:", err);
        setError("Failed to fetch podcast data. Please try again later.");
        setLoading(false);
      }
    };

    fetchPodcastData();
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePlayStateChange = (episodeId, isPlaying) => {
    if (isPlaying) {
      setCurrentlyPlayingId(episodeId);
    } else if (currentlyPlayingId === episodeId) {
      setCurrentlyPlayingId(null);
    }
  };

  const toggleExpand = (episodeId) => {
    setExpandedEpisodes((prev) => ({
      ...prev,
      [episodeId]: !prev[episodeId],
    }));
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  const podcast = JSON.parse(podcastData.podcast);
  const episodes = JSON.parse(podcastData.episodes);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Container component="main" sx={{ mb: 2 }} maxWidth="md">
        <Header podcast={podcast} />
        <RssTextField podcastSession={podcastSession} sx={{ mb: 4 }} />
        <Divider />
        <Grid container spacing={isMobile ? 2 : 4}>
          {episodes.map((episode, index) => (
            <Grid item xs={12} key={index}>
              <Paper sx={{ p: 2 }} elevation={3}>
                <Grid container alignItems="center">
                  <Grid item xs={2} sm={0.8}>
                    <Avatar
                      src={podcast.CoverImageURL}
                      alt={`${podcast.Title} cover`}
                      variant="square"
                    />
                  </Grid>
                  <Grid item xs={10} sm={11.2}>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "1.1rem", // smaller font size for extra-small screens
                          sm: "1.5rem", // medium font size for small screens
                        },
                        fontWeight: "bold",
                      }}
                    >
                      {episode.Title}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      {new Date(episode.PublishedAt).toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <PodcastAudioPlayer
                    episode={episode}
                    podcastSession={podcastSession}
                    preload={index === 0 ? "metadata" : "none"}
                    isCurrentlyPlaying={currentlyPlayingId === episode.ID}
                    onPlayStateChange={(isPlaying) =>
                      handlePlayStateChange(episode.ID, isPlaying)
                    }
                  />
                  <Grid item xs={12} sx={{ marginTop: 1 }}>
                    {renderContent(
                      episode.Content,
                      expandedEpisodes[episode.ID]
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Link
                      component="button"
                      underline="none"
                      onClick={() => toggleExpand(episode.ID)}
                      variant="body2"
                      sx={{ marginTop: 1 }}
                    >
                      {expandedEpisodes[episode.ID] ? "收起" : "展开"}
                    </Link>
                  </Grid>
                  {episode.IsPaid && (
                    <Grid item xs={6} container justifyContent="flex-end">
                      <Chip label="会员专属" sx={{ marginTop: 1 }} />
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default PodcastBlog;
