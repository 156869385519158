import React, { useRef, useEffect } from "react";
import Hls from "hls.js";
import { getFullCdnUrl } from "../../../config";

const VideoPlayer = ({ podcastId, videoDirectory, podcastSession }) => {
  const videoRef = useRef(null);

  const hlsConfig = {
    xhrSetup: function (xhr, url) {
      const parsedUrl = new URL(url);
      if (parsedUrl.pathname.endsWith(".ts")) {
        // 构建新的 segment URL，确保包含 session 参数
        const newUrl = getFullCdnUrl(
          `cdn/video/${podcastId}/${videoDirectory}/${parsedUrl.pathname
            .split("/")
            .pop()}?podcast_session=${podcastSession}`
        );
        xhr.open("GET", newUrl, true);
      }
    },
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const hls = new Hls(hlsConfig);
    const playlistUrl = getFullCdnUrl(
      `cdn/video/${podcastId}/${videoDirectory}/playlist.m3u8?podcast_session=${podcastSession}`
    );

    hls.loadSource(playlistUrl);
    hls.attachMedia(video);

    return () => {
      hls.destroy();
    };
  }, [podcastId, videoDirectory, podcastSession]);

  return (
    <video ref={videoRef} controls style={{ width: "100%", height: "auto" }} />
  );
};

export default VideoPlayer;
