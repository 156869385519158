import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../features/auth/authSlice";
import axios from "axios";
import { useEffect, useState } from "react";
import { Alert, Avatar, Button, TextField } from "@mui/material";
import {
  checkPasswordMessage,
  goodPassword,
  setToken,
  setUser,
  validateEmail,
} from "../utils";
import { getFullApiUrl } from "../config";

export default function SignupPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");

  useEffect(() => {
    setPasswordMessage(checkPasswordMessage(password));
  }, [password]);

  useEffect(() => {
    if (confirmPassword && password !== confirmPassword) {
      setMessage("密码不匹配");
    } else {
      setMessage("");
    }
  }, [password, confirmPassword]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setValidEmail(false);
      return;
    } else {
      setValidEmail(true);
    }

    const auth = {
      email: email,
      password: password,
    };

    try {
      const response = await axios.post(
        getFullApiUrl("/api/v1/auth/signup"),
        auth
      );
      if (response.status === 201 && response.data.token) {
        setToken(response.data.token);
        setUser(JSON.stringify(response.data.user));
        dispatch(
          login({
            user: response.data.user,
            token: response.data.token,
          })
        );
        navigate("/");
      } else {
        // Handle unexpected response
        console.error("Sign failed:", response.data);
        setMessage(response.data.error);
      }
    } catch (err) {
      console.error("Sign failed:", err.response.data.error);
      setMessage(err.response.data.error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          注册
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: "100%" }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="电子邮箱"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {!validEmail && (
            <Alert severity="error" sx={{ mt: 1, width: "100%" }}>
              电子邮件格式错误
            </Alert>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="密码"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {password && (
            <Alert
              severity={passwordMessage !== goodPassword ? "error" : "success"}
              sx={{ mt: 1, width: "100%" }}
            >
              {passwordMessage}
            </Alert>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="确认密码"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {message && (
            <Alert severity="error" sx={{ mt: 1, width: "100%" }}>
              {message}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              passwordMessage !== goodPassword || confirmPassword !== password
            }
          >
            注册
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
