import React, { useRef, useState } from "react";
import { TextField, InputAdornment, Button } from "@mui/material";
import { styled } from "@mui/system";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getFullRssUrl, podcastId } from "../../config";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: theme.palette.grey[100],
    "&.Mui-focused": {
      boxShadow: "0 0 0 2px #e0e7ff",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #e0e0e0",
  },
  "& .MuiOutlinedInput-input": {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
}));

const RssTextField = ({ podcastSession, sx }) => {
  const [copied, setCopied] = useState(false);
  const inputRef = useRef(null);
  const rss =
    podcastSession === null
      ? getFullRssUrl(`/rss/${podcastId}/`)
      : getFullRssUrl(`/rss/${podcastId}/${podcastSession}`);

  const handleCopy = () => {
    navigator.clipboard.writeText(rss).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  return (
    <StyledTextField
      fullWidth
      variant="outlined"
      value={rss}
      sx={sx}
      onClick={handleClick}
      inputRef={inputRef}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <Button
              onClick={handleCopy}
              color={copied ? "success" : "primary"}
              variant={copied ? "contained" : "outlined"}
              startIcon={copied ? <CheckCircleIcon /> : <ContentCopyIcon />}
            >
              {copied ? "复制成功" : "点击复制"}
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default RssTextField;
