import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Avatar, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import { podcast } from "../config";

export default function Header() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container component="main" sx={{ mb: 4 }} maxWidth={750}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mb: 4 }}
        >
          <Avatar
            src={podcast.CoverImageURL}
            variant="square"
            sx={{
              height: "25%",
              width: "25%",
            }}
          />
          <Typography sx={{ fontWeight: "bold" }} variant="h4" align="center">
            {podcast.Title}
          </Typography>
          <Typography
            sx={{ fontStyle: "italic" }}
            variant="h6"
            color="text.secondary"
            align="center"
            gutterBottom
          >
            {podcast.Description}
          </Typography>
        </Stack>
        <Divider />
      </Container>
    </Box>
  );
}
