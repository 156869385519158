import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RichTextEdit from "./Editor";
import { useCallback, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  convertDurationToHHMMSS,
  formatDurationFromSecond,
  getToken,
  slateToHtml,
} from "../../utils";
import { getFullApiUrl } from "../../config";
import { ExpiredPage } from "../ExpiredPage";

const allowedAudioTypes = {
  ".mp3": true,
  ".wav": true,
  ".ogg": true,
  ".m4a": true,
  ".aac": true,
  ".flac": true,
};

const allowedVideoTypes = {
  ".mp4": true,
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary" }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3),
  height: "100vh",
  boxSizing: "border-box",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const LeftPanel = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  [theme.breakpoints.up("md")]: {
    width: "75%",
    paddingRight: theme.spacing(2),
  },
}));

const RightPanel = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "25%",
    paddingLeft: theme.spacing(2),
  },
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function EditorPage() {
  const token = getToken();
  const location = useLocation();
  const podcastId = location.state?.initialPodcastId || "";
  const [expired, setExpired] = useState(false);
  const [title, setTitle] = useState(location.state?.initialTitle || "");
  const [audio, setAudio] = useState(location.state?.initialAudio || "");
  const [video, setVideo] = useState(location.state?.initialVideo || "");
  const [audioDuration, setAudioDuration] = useState(
    location.state?.initialDuration || ""
  );
  const [publishedAt, setPublishedAt] = useState(
    location.state?.initialPublishDate || ""
  );
  const [paid, setPaid] = useState(location.state?.initialPaidStatus || false);
  const [content, setContent] = useState(
    location.state?.initialValue || [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ]
  );
  const isVideo = location.state?.isVideo || false;
  const fileType = isVideo ? "视频文件" : "音频文件";
  const episodeId = location.state?.initialEpisodeId || "";
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(10);
  const [publishType, setPublishType] = useState(
    publishedAt === "" ? "now" : "schedule"
  );
  const cancelTokenSourceRef = useRef(null);

  const handleCancelUpload = useCallback(() => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel("用户取消了上传");
    }
  }, []);

  if (podcastId === "") {
    return <Alert severity="error">没有找到对应播客</Alert>;
  }

  const isAllowedFileType = (fileName, allowedTypes) => {
    const extension = "." + fileName.split(".").pop().toLowerCase();
    return !!allowedTypes[extension];
  };

  const handleSubmit = async () => {
    if (!audio) {
      throw new Error("请先上传音频文件");
    }

    if (!audioDuration) {
      throw new Error("无法获取音频时长");
    }

    //如果元素存在，则获取其值，如果值为空则使用 "0"。
    const priceElement = document.getElementById("episode-price");
    const price = priceElement ? priceElement.value || "0" : "0";
    const data = {
      podcast_id: podcastId,
      title: title,
      audio_file_name: audio,
      duration: audioDuration,
      content: slateToHtml(content),
      is_paid: paid,
      episode_id: episodeId || "",
      price: paid ? price : "0",
      published_at:
        publishType === "schedule"
          ? new Date(publishedAt).toISOString()
          : new Date().toISOString(),
    };
    console.log(data);

    try {
      const response = await axios.post(
        getFullApiUrl("/api/v1/upload/episode"),
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Episode uploaded successfully:", response.data);
        // Handle successful upload (e.g., show success message, redirect)
      } else {
        console.error("Failed to upload episode:", response.data);
        // Handle error (e.g., show error message)
      }
    } catch (err) {
      if (err.response.data.error === "token has expired") {
        setExpired(true);
      } else {
        console.error("Error uploading episode:", err);
        // Handle error (e.g., show error message)
      }
    }
  };

  const getAudioDuration = (file) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio();
      audio.preload = "metadata";

      audio.onloadedmetadata = () => {
        window.URL.revokeObjectURL(audio.src);
        const duration = audio.duration;
        const formatted = formatDurationFromSecond(duration);
        resolve(formatted);
      };

      audio.onerror = (error) => {
        window.URL.revokeObjectURL(audio.src);
        reject(error);
      };

      audio.src = URL.createObjectURL(file);
    });
  };

  const handleUploadAudio = async (files) => {
    if (files.length !== 1) {
      return;
    }
    const file = files[0];
    if (!isAllowedFileType(file.name, allowedAudioTypes)) {
      return;
    }
    setUploading(true);
    setProgress(0);

    const formData = new FormData();
    formData.append("audio", file);
    formData.append("podcastId", podcastId);

    cancelTokenSourceRef.current = axios.CancelToken.source();

    try {
      const response = await axios.post(
        getFullApiUrl("/api/v1/upload/audio"),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
          cancelToken: cancelTokenSourceRef.current.token,
          timeout: 60000,
        }
      );

      console.log("服务器返回的文件标识符（UUID）:", response.data.filename);
      setAudio(response.data.filename);

      const duration = await getAudioDuration(file);
      setAudioDuration(convertDurationToHHMMSS(duration));
      console.log("Audio duration:", duration);
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("上传被取消");
      } else {
        if (err.response.data.error === "token has expired") {
          setExpired(true);
        } else {
          console.error(err);
          alert("文件上传失败");
        }
      }
    } finally {
      setUploading(false);
      setProgress(0);
      cancelTokenSourceRef.current = null;
    }
  };

  const handleUploadVideo = async (files) => {
    if (files.length !== 1) {
      return;
    }
    const file = files[0];
    if (!isAllowedFileType(file.name, allowedVideoTypes)) {
      return;
    }
    setUploading(true);
    setProgress(0);

    const formData = new FormData();
    formData.append("video", file);
    formData.append("podcastId", podcastId);

    cancelTokenSourceRef.current = axios.CancelToken.source();

    try {
      const response = await axios.post(
        getFullApiUrl("/api/v1/upload/video"),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
          cancelToken: cancelTokenSourceRef.current.token,
          timeout: 120000,
        }
      );

      console.log("服务器返回的文件标识符（UUID）:", response.data.filename);
      setVideo(response.data.filename);
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("上传被取消");
      } else {
        if (err.response.data.error === "token has expired") {
          setExpired(true);
        } else {
          console.error(err);
          alert("文件上传失败");
        }
      }
    } finally {
      setUploading(false);
      setProgress(0);
      cancelTokenSourceRef.current = null;
    }
  };

  if (expired) {
    return <ExpiredPage />;
  }

  return (
    <StyledBox>
      <LeftPanel>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="请输入单集标题"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ md: 2, mb: 2 }}
        />
        <RichTextEdit content={content} setContent={setContent} />
      </LeftPanel>
      <RightPanel>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            {fileType}
          </Typography>
          {audio === "" ? (
            !uploading ? (
              <Button
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                上传{fileType}
                <VisuallyHiddenInput
                  type="file"
                  disabled={uploading}
                  onChange={(event) => {
                    if (isVideo) {
                      handleUploadVideo(event.target.files);
                    } else {
                      handleUploadAudio(event.target.files);
                    }
                  }}
                  multiple
                />
              </Button>
            ) : (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <CircularProgressWithLabel value={progress} />
                </Grid>
                <Grid item xs={6}>
                  <Button variant="outlined" onClick={handleCancelUpload}>
                    取消上传
                  </Button>
                </Grid>
              </Grid>
            )
          ) : (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setAudio("");
                  }}
                >
                  更新{fileType}
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
        <Paper elevation={3} sx={{ p: 2, md: 2, marginBottom: 2 }}>
          <Typography variant="h6" gutterBottom>
            发布时间
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <RadioGroup
                row
                name="publish-type-group"
                value={publishType}
                onChange={(event) => {
                  setPublishType(event.target.value);
                }}
              >
                <FormControlLabel
                  value="now"
                  control={<Radio />}
                  label="立即发布"
                />
                <FormControlLabel
                  value="schedule"
                  control={<Radio />}
                  label="定时发布"
                />
              </RadioGroup>
            </Grid>
            {publishType === "schedule" && (
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={dayjs(publishedAt)}
                    onChange={(newValue) =>
                      setPublishedAt(newValue.toISOString())
                    }
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            )}
          </Grid>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, md: 2, marginBottom: 2 }}>
          <Typography variant="h6" gutterBottom>
            单集类型
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <RadioGroup
                row
                name="episode-type-group"
                value={paid.toString()}
                onChange={(event) => {
                  setPaid(event.target.value === "true");
                }}
              >
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="免费"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="付费"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6}>
              {paid && (
                <Input
                  id="episode-price"
                  startAdornment={
                    <InputAdornment position="start">¥</InputAdornment>
                  }
                  placeholder="价格"
                  size="small"
                />
              )}
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            文案模版
          </Typography>
          <Button
            variant="contained"
            component="label"
            fullWidth
            sx={{ mb: 2 }}
          >
            点击设置文案模版或使用模版
            <input type="file" hidden accept="image/*" />
          </Button>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            单集封面
          </Typography>
          <Button
            variant="contained"
            component="label"
            fullWidth
            sx={{ mb: 2 }}
          >
            点击上传封面或使用资料库图片
            <input type="file" hidden accept="image/*" />
          </Button>
        </Paper>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
        >
          {episodeId === "" ? "发布" : "更新"}
        </Button>
      </RightPanel>
    </StyledBox>
  );
}
