import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RssTextField from "../Blog/RssTextField";
import { getPodcastSession } from "../../utils";
import { Link } from "react-router-dom";

export default function HelpPage() {
  const podcastSession = getPodcastSession();
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Container component="main" sx={{ mb: 2 }} maxWidth="md">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mb: 4 }}
        >
          <Typography variant="h3">帮助</Typography>
        </Stack>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
          >
            如何使用本站？
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              本站是边角聊播客订阅网站，提供了网页收听和RSS收听两种模式，并且支持会员收听付费内容。需要收听付费内容，请至知识星球成为我们的付费会员并在本网站绑定知识星球账号。
            </Typography>
            <Typography variant="body1">
              星球链接：
              <a href="https://t.zsxq.com/ciiAK">https://t.zsxq.com/ciiAK</a>
            </Typography>
            <Typography variant="body1">
              您也可以使用微信扫描二维码，加入我们的知识星球：
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/zsxq.jpg`}
              alt="知识星球二维码"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="h6">使用方法</Typography>
            <Typography variant="body1">
              1.
              点击右上角的登录按钮，在登录页面选择注册，输入邮箱和密码完成注册；
            </Typography>
            <Typography variant="body1">
              2.
              注册后选择右上角的知识星球绑定，按照本帮助页下面的“如何绑定知识星球？”绑定好知识星球账号，绑定后您将获得一个独属于您的
              RSS 链接
            </Typography>
            <Typography variant="body1">
              3. 您可以在首页直接收听所有付费节目，也可以将独属于您的 RSS
              链接绑定在播客客户端中进行收听，使用播客客户端收听 RSS
              播客的方法见本页下方的“如何使用RSS订阅？”
            </Typography>
            <RssTextField podcastSession={podcastSession} />
            <Typography variant="body1">不能收听付费节目的通用 rss:</Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/public_rss_url.jpg`}
              alt="通用 rss"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="body1">收听付费节目的专属 rss:</Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/private_rss_url.jpg`}
              alt="专属 rss"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="body1">
              4. 如果遇到任何问题，欢迎随时联系管理员微信：lalambdada
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
          >
            如何知识星球绑定？
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              首先打开知识星球，点击右上角“更多”按钮：
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/zsxq_step1.jpg`}
              alt="知识星球更多按钮"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="body1">
              页面中头像下面第一行就是用户昵称，第二行就是成员编号。
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/zsxq_step2.jpg`}
              alt="知识星球用户昵称和成员编号"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="body1">
              最后，将用户昵称和成员编号填入本站
              <Link to="/zsxq" target="_blank">
                知识星球绑定
              </Link>
              页面，点击“绑定”按钮即可。
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
          >
            如何使用RSS订阅？
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              在您的RSS阅读器中，选择添加新的订阅源，然后输入以下URL：
            </Typography>
            <RssTextField podcastSession={podcastSession} />
            <Typography variant="body1">
              接下来，只需在泛用性播客客户端和RSS阅读器中导入这个链接即可。
            </Typography>
            <Typography variant="h6">苹果播客导入</Typography>
            <Typography variant="body1">
              打开苹果播客，选择资料库，接着选择右上角的・・・图标
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/apple_step1.jpeg`}
              alt="苹果播客导入步骤1"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="body1">
              选择导入RSS源，将刚刚复制的RSS链接粘贴进去，然后点击完成。
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/apple_step2.jpeg`}
              alt="苹果播客导入步骤2"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="body1">
              导入后，在资料库中就可以看到这个播客了。
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/apple_step3.jpeg`}
              alt="苹果播客导入步骤3"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="h6">
              Youtube Music导入（安卓和苹果平台均可使用）
            </Typography>
            <Typography variant="body1">
              打开Youtube
              Music，点击媒体库，然后点击上方的播客图标，最后点击添加播客
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/youtubemusic_step1.jpeg`}
              alt="Youtube Music导入步骤1"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="body1">
              将刚刚复制的RSS链接粘贴进去，然后点击完成。
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/youtubemusic_step2.jpeg`}
              alt="Youtube Music导入步骤2"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="body1">
              导入后，在媒体库中就可以看到这个播客了。
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/youtubemusic_step3.jpeg`}
              alt="Youtube Music导入步骤3"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="h6">
              Pocket Casts（安卓和苹果平台均可使用）
            </Typography>
            <Typography variant="body1">
              1. 打开Pocket Casts，在 App 上方搜索框粘贴刚才复制的RSS链接。
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/pocketcasts_step1.jpeg`}
              alt="Pocket Casts导入步骤1"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="body1">
              2. 按输入法的回车键进行加载，加载完成即可看到订阅和收听播客。
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/pocketcasts_step2.jpeg`}
              alt="Pocket Casts导入步骤2"
              style={{ width: "50%", height: "auto" }}
            />
            <Typography variant="h6">其他支持 rss 的播客客户端</Typography>
            <Typography variant="body1">
              市场上有大量泛用性播客客户端，这里不一一举例，请自行搜索教程。
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Box>
  );
}
