import { Box, Button, Container, TextField } from "@mui/material";
import axios from "axios";
import { getFullApiUrl } from "../../config";
import { getToken } from "../../utils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/auth/authSlice";

export default function MigratePodcastPage() {
  const token = getToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const migrate = {
      rss: data.get("rss"),
    };

    try {
      const response = await axios.post(
        getFullApiUrl("/api/v1/upload/migrate"),
        migrate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
      } else {
        // Handle unexpected response
        console.error("Migrate failed:", response.data);
      }
    } catch (err) {
      if (err.response.data.error === "token has expired") {
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("podcast_session");
          dispatch(logout());
          navigate("/login");
        }, 2000);
      } else {
        console.error("Migrate failed:", err.response.data.error);
      }
    }
  };
  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        paddingTop: "20vh",
      }}
      maxWidth="md"
    >
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="rss"
          label="RSS链接"
          name="rss"
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          开始迁移
        </Button>
      </Box>
    </Container>
  );
}
