import { Box, Container, Grid, Paper, styled, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFullApiUrl } from "../../config";
import { getToken } from "../../utils";
import { ExpiredPage } from "../ExpiredPage";
import { LoadingPage } from "../LoadingPage";
import { ErrorPage } from "../ErrorPage";

const PodcasterPodcastsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [podcastData, setPodcastData] = useState([]);
  const [error, setError] = useState(null);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const fetchPodcastsData = async () => {
      try {
        const token = getToken();
        setLoading(true);

        const podcastsResponse = await axios.post(
          getFullApiUrl("/api/v1/podcaster/podcasts"),
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setPodcastData(podcastsResponse.data);
      } catch (err) {
        if (err.response.data.error === "token has expired") {
          setExpired(true);
        } else {
          console.error("Error fetching RSS data:", err);
          setError("Failed to load podcast data. Please try again later.");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchPodcastsData();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  if (expired) {
    return <ExpiredPage />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  const handlePodcastClick = (podcastId) => {
    navigate(`/podcaster/episodes`, {
      state: { podcastId },
    });
  };

  const Item = styled(Paper)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: 200,
    height: 200,
  }));

  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "20vh",
      }}
      maxWidth="lg"
    >
      <Grid container spacing={2} justifyContent="center">
        {podcastData.map((podcast) => (
          <Grid item key={podcast.ID}>
            <Item onClick={() => handlePodcastClick(podcast.ID)} elevation={3}>
              <Box
                component="img"
                src={podcast.CoverImageURL}
                alt={podcast.Title}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Item>
            <Typography
              sx={{
                mt: 1,
                width: "100%",
                textAlign: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              variant="h6"
            >
              {podcast.Title}
            </Typography>
          </Grid>
        ))}
        <Grid item>
          <Item
            onClick={() => {
              navigate("/podcaster/create_podcast");
            }}
            elevation={3}
          >
            <AddIcon sx={{ fontSize: 40 }} />
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PodcasterPodcastsPage;
