import {
  Box,
  Divider,
  Paper,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ImageIcon from "@mui/icons-material/Image";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import React, { useCallback, useMemo } from "react";
import {
  createEditor,
  Editor,
  Range,
  Element as SlateElement,
  Transforms,
} from "slate";
import { withHistory } from "slate-history";
import {
  Editable,
  Slate,
  useFocused,
  useSelected,
  useSlate,
  withReact,
} from "slate-react";
import isHotkey from "is-hotkey";
import imageExtensions from "image-extensions";
import isUrl from "is-url";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+k": "link",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];

const EditorContainer = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(3),
  overflow: "hidden",
}));

const EditorContent = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
});

const EditorScrollable = styled(Box)({
  flex: 1,
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.2)",
    borderRadius: "4px",
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: "1px solid transparent",
    },
}));

const RichTextEdit = ({ content, setContent }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withLinks(withImages(withHistory(withReact(createEditor())))),
    []
  );

  const handleKeyDown = useCallback(
    (event) => {
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault();
          const mark = HOTKEYS[hotkey];
          if (mark === "link") {
            const url = window.prompt("Enter the URL of the link:");
            if (url) insertLink(editor, url);
          } else {
            toggleMark(editor, mark);
          }
        }
      }
      if (event.key === "Enter" || event.key === "Backspace") {
        const { selection } = editor;
        if (selection && Range.isCollapsed(selection)) {
          const [match] = Editor.nodes(editor, {
            match: (n) =>
              n.type === "list-item" &&
              n.children.length === 1 &&
              n.children[0].text === "",
          });

          if (match) {
            event.preventDefault();
            Transforms.unwrapNodes(editor, {
              match: (n) =>
                n.type === "bulleted-list" || n.type === "numbered-list",
              split: true,
            });
            Transforms.setNodes(editor, { type: "paragraph" });
            return;
          }
        }
      }
    },
    [editor]
  );

  return (
    <EditorContainer elevation={3}>
      <Slate
        editor={editor}
        initialValue={content}
        onChange={(value) => setContent(value)}
      >
        <StyledToggleButtonGroup size="small">
          <MarkButton format="bold" icon={<FormatBoldIcon />} />
          <MarkButton format="italic" icon={<FormatItalicIcon />} />
          <MarkButton format="underline" icon={<FormatUnderlinedIcon />} />
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          <BlockButton
            format="numbered-list"
            icon={<FormatListNumberedIcon />}
          />
          <BlockButton
            format="bulleted-list"
            icon={<FormatListBulletedIcon />}
          />
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          <InsertImageButton />
          <InsertLinkButton />
          <RemoveLinkButton />
        </StyledToggleButtonGroup>
        <EditorContent>
          <EditorScrollable>
            <Box
              sx={{
                p: 2,
                minHeight: "100%",
              }}
            >
              <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder="在这里编辑show notes"
                style={{
                  minHeight: "100%",
                  outline: "none",
                }}
                onKeyDown={handleKeyDown}
              />
            </Box>
          </EditorScrollable>
        </EditorContent>
      </Slate>
    </EditorContainer>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  });

  const newProperties = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      value={format}
      selected={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      value={format}
      selected={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

// image
const withImages = (editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertImage = (editor, url) => {
  const text = { text: "" };
  const image = { type: "image", url, children: [text] };
  Transforms.insertNodes(editor, image);
  Transforms.insertNodes(editor, {
    type: "paragraph",
    children: [{ text: "" }],
  });
};

const Element = (props) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case "link":
      return <LinkComponent {...props} />;
    case "image":
      return <Image {...props} />;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Image = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <div {...attributes}>
      <div
        contentEditable={false}
        style={{
          padding: "3px",
          margin: "-3px",
          display: "inline-block",
          borderRadius: "4px",
          backgroundColor:
            selected && focused ? "rgba(180, 213, 255, 0.2)" : "transparent",
        }}
      >
        <img
          src={element.url}
          alt=""
          style={{
            display: "block",
            maxWidth: "100%",
            maxHeight: "20em",
            borderRadius: "2px",
            outline: selected && focused ? "2px solid #B4D5FF" : "none",
            outlineOffset: "-2px",
          }}
        />
      </div>
      {children}
    </div>
  );
};

const InsertImageButton = () => {
  const editor = useSlate();
  return (
    <ToggleButton
      value="insert-image"
      onClick={(event) => {
        event.preventDefault();
        const url = window.prompt("Enter the URL of the image:");
        if (url && !isImageUrl(url)) {
          alert("URL is not an image");
          return;
        }
        url && insertImage(editor, url);
      }}
    >
      <ImageIcon />
    </ToggleButton>
  );
};

const isImageUrl = (url) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext);
};

const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) =>
    ["link"].includes(element.type) || isInline(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
  return !!link;
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

const LinkComponent = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <a
      {...attributes}
      href={element.url}
      style={{
        padding: "3px",
        margin: "-3px",
        display: "inline-block",
        borderRadius: "4px",
        backgroundColor:
          selected && focused ? "rgba(180, 213, 255, 0.2)" : "transparent",
        textDecoration: "underline",
      }}
    >
      {children}
    </a>
  );
};

const InsertLinkButton = () => {
  const editor = useSlate();
  return (
    <ToggleButton
      value="insert-link"
      selected={isLinkActive(editor)}
      onClick={(event) => {
        event.preventDefault();
        const url = window.prompt("Enter the URL of the link:");
        if (url) insertLink(editor, url);
      }}
    >
      <LinkIcon />
    </ToggleButton>
  );
};

const RemoveLinkButton = () => {
  const editor = useSlate();
  return (
    <ToggleButton
      value="remove-link"
      selected={isLinkActive(editor)}
      onClick={(event) => {
        event.preventDefault();
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
    >
      <LinkOffIcon />
    </ToggleButton>
  );
};

export default RichTextEdit;
