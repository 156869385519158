import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import { useDispatch } from "react-redux";
import PrivateRoute from "./components/PrivateRoute";
import { login } from "./features/auth/authSlice";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { responsiveFontSizes } from "@mui/material";
import ZsxqPage from "./components/Blog/ZsxqPage";
import SignupPage from "./components/SignupPage";
import PodcasterPodcastsPage from "./components/Podcaster/PodcasterPodcastsPage";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store";
import CreatePodcastPage from "./components/Podcaster/CreatePodcastPage";
import MigratePodcastPage from "./components/Podcaster/MigratePodcastPage";
import PodcasterEpisodesPage from "./components/Podcaster/PodcasterEpisodesPage";
import EditorPage from "./components/Podcaster/EditorPage";
import PodcastBlog from "./components/Blog/PodcastBlogPage";
import Appbar from "./components/AppBar";
import Copyright from "./components/Copyright";
import HelpPage from "./components/Help/HelpPage";
import EpisodePage from "./components/Blog/EpisodePage";
import { getToken, getUser } from "./utils";
import { LoadingPage } from "./components/LoadingPage";
import VideoBlogsPage from "./components/Blog/VideoBlogs/VideoBlogsPage";
import { VideoPage } from "./components/Blog/VideoBlogs/VideoPage";

// Create a theme instance.
let theme = createTheme();
// Apply responsive font sizes to the theme
theme = responsiveFontSizes(theme);

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedUser = getUser();
    const storedToken = getToken();
    if (storedUser && storedToken) {
      dispatch(login({ user: storedUser, token: storedToken }));
    }
    setIsLoading(false);
  }, [dispatch]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Appbar />
        <Routes>
          <Route path="/" element={<PodcastBlog />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/help" element={<HelpPage />} />
          <Route
            path="/podcaster"
            element={
              <PrivateRoute>
                <PodcasterPodcastsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/podcaster/episodes"
            element={
              <PrivateRoute>
                <PodcasterEpisodesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/podcaster/migrate_podcast"
            element={
              <PrivateRoute>
                <MigratePodcastPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/podcaster/create_podcast"
            element={
              <PrivateRoute>
                <CreatePodcastPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/podcaster/editor"
            element={
              <PrivateRoute>
                <EditorPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/zsxq"
            element={
              <PrivateRoute>
                <ZsxqPage />
              </PrivateRoute>
            }
          />
          <Route path="/episode/:id" element={<EpisodePage />} />
          <Route path="/videos" element={<VideoBlogsPage />} />
          <Route path="/video/:id" element={<VideoPage />} />
        </Routes>
        <Copyright />
      </ThemeProvider>
    </PersistGate>
  );
};

export default App;
