import { Container, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { logout } from "../features/auth/authSlice";

export const ExpiredPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("podcast_session");
      dispatch(logout());
      navigate("/login");
    }, 2000);

    // 清理函数
    return () => clearTimeout(timer);
  }, [navigate, dispatch]);
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">您的登录信息已过期，请重新登录。</Typography>
      </Box>
    </Container>
  );
};
