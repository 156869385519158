const apiUrl = "https://api.leftovertalk.com/";
const cdnUrl = "https://cdn.leftovertalk.com/";
const rssUrl = "https://rss.leftovertalk.com/";
export const podcastId = "D2c1Bdu6";
export const ITEMS_PER_PAGE = 10;

export function getFullApiUrl(path) {
  if (path.startsWith("/")) {
    return `${apiUrl}${path.substring(1)}`;
  }
  return `${apiUrl}${path}`;
}

export function getFullCdnUrl(path) {
  if (path.startsWith("/")) {
    return `${cdnUrl}${path.substring(1)}`;
  }
  return `${cdnUrl}${path}`;
}

export function getFullRssUrl(path) {
  if (path.startsWith("/")) {
    return `${rssUrl}${path.substring(1)}`;
  }
  return `${rssUrl}${path}`;
}

export const podcast = {
  Title: "边角聊",
  Description:
    "边角聊是一档跨界的谈话类播客节目，希望从“边角”出发探寻“本质”，让“闲聊”变得“有料”。欢迎在各大播客平台搜索“边角聊”订阅收听。",
  CoverImageURL: `${process.env.PUBLIC_URL}/logo512.png`,
};
