export const validateEmail = (email) => {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null
  );
};

export const goodPassword = "密码强度高";

export const checkPasswordMessage = (password) => {
  if (!password.match(/[a-z]+/)) return "缺少小写字母";
  if (!password.match(/[A-Z]+/)) return "缺少大写字母";
  if (!password.match(/[0-9]+/)) return "缺少数字";
  if (password.length < 8) return "长度小于8";
  if (password.length > 64) return "长度大于64";
  return goodPassword;
};
