import { jsx } from "slate-hyperscript";
import escapeHtml from "escape-html";
import { Text } from "slate";

export const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  }

  const children = Array.from(el.childNodes).map(deserialize).flat();

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    case "BLOCKQUOTE":
      return jsx("element", { type: "quote" }, children);
    case "P":
      return jsx("element", { type: "paragraph" }, children);
    case "A":
      return jsx(
        "element",
        { type: "link", url: el.getAttribute("href") },
        children
      );
    case "IMG":
      return jsx(
        "element",
        {
          type: "image",
          url: el.getAttribute("src"),
          alt: el.getAttribute("alt"),
        },
        [{ text: "" }]
      );
    case "STRONG":
    case "B":
      return children.map((child) =>
        typeof child === "string"
          ? { text: child, bold: true }
          : { ...child, bold: true }
      );
    case "EM":
    case "I":
      return children.map((child) =>
        typeof child === "string"
          ? { text: child, italic: true }
          : { ...child, italic: true }
      );
    case "U":
      return children.map((child) =>
        typeof child === "string"
          ? { text: child, underline: true }
          : { ...child, underline: true }
      );
    default:
      return children;
  }
};

export const htmlToSlate = (html) => {
  const parsed = new DOMParser().parseFromString(html, "text/html");
  return deserialize(parsed.body);
};

export const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children = node.children.map((n) => serialize(n)).join("");

  switch (node.type) {
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `<p>${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case "image":
      return `<img src="${escapeHtml(node.url)}" alt="${escapeHtml(
        node.alt || ""
      )}" />`;
    case "bulleted-list":
      return `<ul>${children}</ul>`;
    case "numbered-list":
      return `<ol>${children}</ol>`;
    case "list-item":
      return `<li>${children}</li>`;
    default:
      return children;
  }
};

export const slateToHtml = (value) => {
  return value.map((node) => serialize(node)).join("");
};
