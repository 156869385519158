import { Box, Container, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import PodcastAudioPlayer from "./PodcastAudioPlayer";
import axios from "axios";
import { getFullApiUrl } from "../../config";
import { renderCustomContent } from "../../utils";
import { useLocation } from "react-router-dom";
import { LoadingPage } from "../LoadingPage";
import { ErrorPage } from "../ErrorPage";

const EpisodePage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [episode, setEpisode] = useState(null);

  const { episodeId, podcastSession } = useMemo(() => {
    const episodeId = window.location.pathname.split("/").pop();
    const searchParams = new URLSearchParams(location.search);
    const session = searchParams.get("podcast_session");
    return { episodeId, podcastSession: session };
  }, [location.search]);

  useEffect(() => {
    const fetchPodcastsData = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          getFullApiUrl(`/api/v1/subscribe/get_episode?id=${episodeId}`)
        );

        setEpisode(response.data.episode);
      } catch (err) {
        console.error("Error fetching episode data:", err);
        setError("Failed to load episode data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchPodcastsData();
  }, [episodeId, podcastSession]);

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Container component="main" sx={{ mb: 2 }} maxWidth="md">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mb: 4 }}
        >
          <Typography variant="h3">{episode.Title}</Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            {new Date(episode.PublishedAt).toLocaleDateString()}
          </Typography>
          <PodcastAudioPlayer
            episode={episode}
            podcastSession={podcastSession}
            sx={{ mt: 4, mb: 4 }}
          />
        </Stack>
        {renderCustomContent(episode.Content)}
      </Container>
    </Box>
  );
};

export default EpisodePage;
