import { Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function CreatePodcastPage() {
  const navigate = useNavigate();
  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        paddingTop: "20vh",
      }}
      maxWidth="md"
    >
      <Typography component="h1" variant="h4">
        创建新播客 or 迁移原有播客
      </Typography>
      <Button variant="contained" sx={{ mt: 3 }}>
        创建新播客
      </Button>
      <Button
        variant="contained"
        sx={{ mt: 3 }}
        onClick={() => {
          navigate("/podcaster/migrate_podcast");
        }}
      >
        迁移原有播客
      </Button>
    </Container>
  );
}
