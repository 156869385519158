import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../features/auth/authSlice";
import axios from "axios";
import { useState } from "react";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  checkPasswordMessage,
  goodPassword,
  setPodcastSession,
  setToken,
  setUser,
  validateEmail,
} from "../utils";
import { getFullApiUrl, podcastId } from "../config";

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [openForgotPassword, setOpenForgotPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const auth = {
      email: data.get("email"),
      password: data.get("password"),
      podcast_id: podcastId,
    };
    if (!validateEmail(auth.email)) {
      setMessage("用户名或密码错误");
      return;
    }
    if (checkPasswordMessage(auth.password) !== goodPassword) {
      setMessage("用户名或密码错误");
      return;
    }

    try {
      const response = await axios.post(
        getFullApiUrl("/api/v1/auth/login"),
        auth
      );
      if (response.status === 200 && response.data.token) {
        setToken(response.data.token);
        if (response.data.podcast_session) {
          setPodcastSession(response.data.podcast_session);
        }
        setUser(JSON.stringify(response.data.user));
        dispatch(
          login({
            user: response.data.user,
            token: response.data.token,
          })
        );
        navigate("/");
      } else {
        // Handle unexpected response
        console.error("Login failed:", response.data);
        setMessage(response.data.error);
      }
    } catch (err) {
      console.error("Get error when login:", err);
      setMessage(err.response.data.error);
    }
  };

  const handleForgotPasswordOpen = () => {
    setOpenForgotPassword(true);
  };

  const handleForgotPasswordClose = () => {
    setOpenForgotPassword(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          登录
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="电子邮箱"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="密码"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {message && (
            <Alert severity="error" sx={{ mt: 1 }}>
              {message}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            登录
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                variant="body2"
                component={Button}
                onClick={handleForgotPasswordOpen}
              >
                忘记密码？
              </Link>
            </Grid>
            <Grid item xs container justifyContent="flex-end">
              <Link href="/signup" variant="body2">
                注册
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Dialog open={openForgotPassword} onClose={handleForgotPasswordClose}>
        <DialogTitle>忘记密码</DialogTitle>
        <DialogContent dividers>
          <Typography>
            由于还未申请到邮件发送服务，请联系管理员重置密码。
          </Typography>
          <Typography>管理员微信号：lalambdada</Typography>
          <Typography>管理员微信号二维码：</Typography>
          <img
            src={`${process.env.PUBLIC_URL}/images/wechat.jpeg`}
            alt="微信号二维码"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleForgotPasswordClose}>关闭</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
