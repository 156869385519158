import { Box, CircularProgress, Container, Typography } from "@mui/material";
export const LoadingPage = () => {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
        <Typography>
          {Math.random() > 0.7 ? "少女祈祷中..." : "锐意加载中..."}
        </Typography>
      </Box>
    </Container>
  );
};
