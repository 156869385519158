import {
  Alert,
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getFullApiUrl, podcastId } from "../../config";
import { getPodcastSession, getToken, setPodcastSession } from "../../utils";
import { useDispatch } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import { ExpiredPage } from "../ExpiredPage";
import { LoadingPage } from "../LoadingPage";
import { ErrorPage } from "../ErrorPage";

const ZsxqPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [zsxqMessage, setZsxqMessage] = useState("");
  const [memberName, setMemberName] = useState("");
  const [memberNumber, setMemberNumber] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expired, setExpired] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const token = getToken() || "";
  useEffect(() => {
    const podcastSession = getPodcastSession();
    if (podcastSession !== null) {
      navigate("/");
    }
    setLoading(true);
    const fetchAccountVerified = async () => {
      try {
        const response = await axios.post(
          getFullApiUrl("/api/v1/auth/account_verified"),
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // setIsVerified(response.data.is_verified);
        setIsVerified(true);
        setLoading(false);
      } catch (err) {
        if (err.response.data.error === "token has expired") {
          setExpired(true);
        } else {
          console.error("Error fetching podcast data:", err);
          setError("Failed to fetch podcast data. Please try again later.");
          setLoading(false);
        }
      }
    };

    fetchAccountVerified();
  }, [token, navigate]);

  const handleResendEmail = async () => {
    try {
      const token = getToken();
      const response = await axios.post(
        getFullApiUrl("/api/v1/auth/send_email"),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setIsDisabled(true);
        const interval = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
        setTimeout(() => {
          clearInterval(interval);
          setIsDisabled(false);
          setCountdown(30);
        }, 30000);
        setZsxqMessage("验证邮件发送成功");
      } else {
        setZsxqMessage("验证邮件发送失败");
      }
    } catch (err) {
      if (err.response.data.error === "token has expired") {
        setError("登录已过期，请重新登录。");
        setLoading(false);
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("podcast_session");
          dispatch(logout());
          navigate("/login");
        }, 2000);
      } else {
        console.error("Error resending email:", error);
        setZsxqMessage("验证邮件发送失败");
      }
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (!isVerified) {
    return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
            知识星球会员通道
          </Typography>
          <Stack spacing={1}>
            <Alert severity="error">
              请检查注册邮箱，点击邮箱中的链接完成验证，验证后刷新本页面后才可以绑定。
            </Alert>
            <Alert severity="info">
              如果邮箱没有收到验证邮件，请检查邮箱的垃圾邮件文件夹。
            </Alert>
            {zsxqMessage && (
              <Alert
                severity={
                  zsxqMessage === "验证邮件发送成功" ? "success" : "error"
                }
              >
                {zsxqMessage}。
              </Alert>
            )}
            <Button
              onClick={handleResendEmail}
              variant="outlined"
              disabled={isDisabled}
            >
              {isDisabled
                ? `点击重新发送验证邮件（${countdown}s）`
                : "点击重新发送验证邮件"}
            </Button>
          </Stack>
        </Box>
      </Container>
    );
  }

  if (expired) {
    return <ExpiredPage />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  const handleMemberSubmit = async (event) => {
    event.preventDefault();
    if (memberName === "") {
      setZsxqMessage("用户昵称或成员编号错误");
      return;
    }
    const isnum = /^\d+$/.test(memberNumber);
    if (!isnum) {
      setZsxqMessage("用户昵称或成员编号错误");
      return;
    }

    try {
      const response = await axios.post(
        getFullApiUrl("/api/v1/auth/verify_zsxq"),
        {
          member_name: memberName,
          member_number: memberNumber,
          podcast_id: podcastId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setPodcastSession(response.data.podcastSession);
        navigate("/");
      } else {
        console.error("Verify failed:", response.data);
        setZsxqMessage(response.data.error);
      }
    } catch (err) {
      if (err.response.data.error === "token has expired") {
        setError("登录已过期，请重新登录。");
        setLoading(false);
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("podcast_session");
          dispatch(logout());
          navigate("/login");
        }, 2000);
      } else {
        console.error("Verify failed:", err.response.data.error);
        setZsxqMessage(err.response.data.error);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
          知识星球会员通道
        </Typography>
        <Box
          component="form"
          onSubmit={handleMemberSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="member_name"
            label="会员昵称"
            name="member_name"
            autoComplete="会员昵称"
            value={memberName}
            onChange={(e) => setMemberName(e.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="member_number"
            label="成员编号"
            id="member_number"
            autoComplete="成员编号"
            value={memberNumber}
            onChange={(e) => setMemberNumber(e.target.value)}
          />
          {zsxqMessage && (
            <Alert severity="error" sx={{ mt: 1 }}>
              {zsxqMessage}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isVerified}
            sx={{ mt: 3, mb: 2 }}
          >
            提交
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ZsxqPage;
