import { useState, useEffect } from "react";
import {
  getFullApiUrl,
  ITEMS_PER_PAGE,
  podcast,
  podcastId,
} from "../../../config";
import { ErrorPage } from "../../ErrorPage";
import { LoadingPage } from "../../LoadingPage";
import {
  Avatar,
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  Pagination,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { truncateContent } from "../../../utils";
import axios from "axios";
import Header from "../../Header";
import { useTheme } from "@emotion/react";
import { Link, useNavigate } from "react-router-dom";

const VideoBlogsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [videos, setVideos] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchVideoBlogs = async () => {
      try {
        const response = await axios.get(
          getFullApiUrl("/api/v1/subscribe/get_videos"),
          {
            params: {
              podcast: podcastId,
              start: (page - 1) * ITEMS_PER_PAGE,
              end: page * ITEMS_PER_PAGE,
            },
          }
        );
        setVideos(response.data.videos);
        setTotalPages(
          Math.ceil(response.data.number_of_episodes / ITEMS_PER_PAGE)
        );
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchVideoBlogs();
  }, [page]);

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleVideoClick = (video) => {
    navigate(`/video/${video.ID}`, {
      state: {
        video: video,
      },
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Container component="main" sx={{ mb: 2 }} maxWidth="md">
        <Header podcast={podcast} />
        <Divider />
        <Grid container spacing={isMobile ? 2 : 4}>
          {videos.map((video, index) => (
            <Grid item xs={12} key={index}>
              <Paper
                sx={{ p: 2, cursor: "pointer" }}
                elevation={3}
                onClick={() => handleVideoClick(video)}
              >
                <Grid container alignItems="center">
                  <Grid item xs={2} sm={0.8}>
                    <Avatar
                      src={podcast.CoverImageURL}
                      alt={`${podcast.Title} cover`}
                      variant="square"
                    />
                  </Grid>
                  <Grid item xs={10} sm={11.2}>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "1.1rem", // smaller font size for extra-small screens
                          sm: "1.5rem", // medium font size for small screens
                        },
                        fontWeight: "bold",
                      }}
                    >
                      {video.Title}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      {new Date(video.PublishedAt).toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 1 }}>
                    {truncateContent(video.Content)}
                  </Grid>
                  {video.IsPaid && (
                    <Grid item xs={6}>
                      <Chip label="会员专属" sx={{ marginTop: 1 }} />
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default VideoBlogsPage;
