import { Box, Container, Typography } from "@mui/material";

export const ErrorPage = ({ error }) => {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    </Container>
  );
};
