import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Button, useMediaQuery } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { getFullApiUrl } from "../config";
import { getPodcastSession } from "../utils";

export default function Appbar() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const isSmScreen = useMediaQuery(theme.breakpoints.only("sm"));
  const isMdScreen = useMediaQuery(theme.breakpoints.only("md"));
  const { token } = useSelector((state) => state.auth);
  const podcastSession = getPodcastSession();

  const getAppBarHeight = () => {
    if (isXsScreen) return "32px";
    if (isSmScreen) return "32px";
    if (isMdScreen) return "36px";
    return "40px"; // 默认高度，用于大屏幕
  };

  const handleLogout = () => {
    const sendLogoutRequest = async () => {
      try {
        await axios.post(
          getFullApiUrl("/api/v1/auth/logout"),
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.error("Logout failed:", error);
      }
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("podcast_session");
      dispatch(logout());
      window.location.href = "/";
    };
    sendLogoutRequest();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        color="transparent"
        position="relative"
        sx={{
          boxShadow: "none",
          mb: 8,
        }}
      >
        <Toolbar
          sx={{
            height: getAppBarHeight(),
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                sx={{
                  padding: 0,
                  minWidth: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/logo_mono.png"}
                  alt="Logo"
                  style={{
                    height: getAppBarHeight(),
                    width: "auto",
                    display: "block",
                  }}
                />
              </Button>
            </Link>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Link to="/videos" style={{ textDecoration: "none" }}>
              <Button sx={{ color: "#000" }}>视频</Button>
            </Link>
            <Link to="/help" style={{ textDecoration: "none" }}>
              <Button sx={{ color: "#000" }}>帮助</Button>
            </Link>
            {token !== null && podcastSession === null && (
              <Link to="/zsxq" style={{ textDecoration: "none" }}>
                <Button sx={{ color: "#000" }}>知识星球绑定</Button>
              </Link>
            )}
            {token === null ? (
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Button sx={{ color: "#000" }}>登录</Button>
              </Link>
            ) : (
              <Button sx={{ color: "#000" }} onClick={handleLogout}>
                登出
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
