import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export {
  validateEmail,
  checkPasswordMessage,
  goodPassword,
} from "./validation";

export {
  serialize,
  deserialize,
  htmlToSlate,
  slateToHtml,
} from "./serializing";

export {
  getPodcastSession,
  setPodcastSession,
  getToken,
  setToken,
  getUser,
  setUser,
} from "./localStorage";

export function formatDurationFromSecond(value) {
  const minute = Math.floor(value / 60);
  const secondLeft = Math.floor(value - minute * 60);
  return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
}

export function convertDurationToHHMMSS(duration) {
  const parts = duration.split(":").map(Number);
  if (parts.length !== 2 || parts[0] < 60) {
    return duration; // 如果不是 mm:ss 格式或者分钟数不大于60，直接返回原始值
  }

  const totalMinutes = parts[0];
  const seconds = parts[1];
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // TODO: handle the episode duration whoes hours > 24
  return [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0"),
  ].join(":");
}

export const renderCustomContent = (content) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");

  const renderNode = (node) => {
    switch (node.nodeName.toLowerCase()) {
      case "p":
        return (
          <Typography paragraph key={Math.random()}>
            {Array.from(node.childNodes).map(renderNode)}
          </Typography>
        );
      case "strong":
        return (
          <strong key={Math.random()}>
            {Array.from(node.childNodes).map(renderNode)}
          </strong>
        );
      case "a":
        return (
          <Link
            href={node.getAttribute("href")}
            key={Math.random()}
            target="_blank"
            rel="noopener noreferrer"
          >
            {node.textContent}
          </Link>
        );
      case "figure":
        return (
          <Box component="figure" key={Math.random()} sx={{ my: 2 }}>
            {Array.from(node.childNodes).map(renderNode)}
          </Box>
        );
      case "img":
        return (
          <img
            src={node.src}
            alt={node.alt}
            key={Math.random()}
            style={{ maxWidth: "100%", height: "auto" }}
          />
        );
      case "#text":
        return node.textContent;
      default:
        return null;
    }
  };

  return Array.from(doc.body.childNodes).map(renderNode);
};

export const truncateContent = (content) => {
  const CONTENT_MAX_LENGTH = 100;
  const plainText = content.replace(/<[^>]+>/g, "");
  return plainText.length > CONTENT_MAX_LENGTH
    ? plainText.slice(0, CONTENT_MAX_LENGTH) + "..."
    : plainText;
};

export const renderContent = (content, isExpanded) => {
  if (isExpanded) {
    return renderCustomContent(content);
  }
  return <Typography variant="body1">{truncateContent(content)}</Typography>;
};
