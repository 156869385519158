export const getPodcastSession = () => {
  return localStorage.getItem("podcast_session");
};

export const setPodcastSession = (session) => {
  localStorage.setItem("podcast_session", session);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const getUser = () => {
  return localStorage.getItem("user");
};

export const setUser = (user) => {
  localStorage.setItem("user", user);
};
