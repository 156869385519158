import { Link, useLocation } from "react-router-dom";
import { ErrorPage } from "../../ErrorPage";
import { getPodcastSession, renderCustomContent } from "../../../utils";
import {
  Alert,
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import VideoPlayer from "./VideoPlayer";

export const VideoPage = () => {
  const location = useLocation();
  const video = location.state.video;

  if (!video) {
    return <ErrorPage error="视频不存在" />;
  }

  const podcastSession = getPodcastSession();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Container component="main" sx={{ mb: 2 }} maxWidth="md">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mb: 4 }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/">
              首页
            </Link>
            <Link underline="hover" color="inherit" to="/videos">
              视频
            </Link>
            <Typography sx={{ color: "text.primary" }}>视频详情</Typography>
          </Breadcrumbs>
          <Alert severity="warning">
            由于水印未去除，视频先暂时下架，处理后会重新上架。
          </Alert>
          <Typography variant="h3">{video.Title}</Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            {new Date(video.PublishedAt).toLocaleDateString()}
          </Typography>
          <VideoPlayer
            podcastId={video.PodcastID}
            videoDirectory={video.VideoDirectory}
            podcastSession={podcastSession}
          />
        </Stack>
        <Divider sx={{ mb: 4 }} />
        {renderCustomContent(video.Content)}
      </Container>
    </Box>
  );
};
