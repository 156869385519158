import {
  Box,
  Grid,
  Slider,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Forward30Icon from "@mui/icons-material/Forward30";
import Replay10Icon from "@mui/icons-material/Replay10";
import { useEffect, useRef, useState } from "react";
import { formatDurationFromSecond } from "../../utils";
import { useTheme } from "@emotion/react";
import { getFullCdnUrl } from "../../config";

const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

const PodcastAudioPlayer = ({
  episode,
  podcastSession,
  preload,
  isCurrentlyPlaying,
  onPlayStateChange,
}) => {
  const convertDurationToSeconds = (duration) => {
    const parts = duration.split(":").map(Number);
    if (parts.length === 3) {
      // Hours:Minutes:Seconds format
      const [hours, minutes, seconds] = parts;
      return hours * 3600 + minutes * 60 + seconds;
    } else if (parts.length === 2) {
      // Minutes:Seconds format
      const [minutes, seconds] = parts;
      return minutes * 60 + seconds;
    } else if (parts.length === 1) {
      // Seconds format
      const [seconds] = parts;
      return seconds;
    } else {
      console.error("Invalid duration format:", duration);
      return 0; // 或者返回一个默认值
    }
  };
  const playbackRate = [
    { text: "1.0x", rate: 1, next: 1 },
    { text: "1.3x", rate: 1.3, next: 2 },
    { text: "1.5x", rate: 1.5, next: 3 },
    { text: "2.0x", rate: 2, next: 4 },
    { text: "0.7x", rate: 0.7, next: 0 },
  ];

  const durationInSeconds = convertDurationToSeconds(episode.Duration);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const audioRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRateAndText, setPlaybackRateAndText] = useState(
    playbackRate[0]
  );
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;
    const updatePosition = () => {
      setPosition(Math.floor(audio.currentTime));
    };
    audio.addEventListener("timeupdate", updatePosition);
    return () => {
      audio.removeEventListener("timeupdate", updatePosition);
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRateAndText.rate;
    }
  }, [playbackRateAndText]);

  useEffect(() => {
    if (!isCurrentlyPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  }, [isCurrentlyPlaying]);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
    onPlayStateChange(!isPlaying);
  };

  const handleReplay = () => {
    audioRef.current.currentTime -= 10;
  };

  const handleForward = () => {
    audioRef.current.currentTime += 30;
  };

  const handleSliderChange = (event, newValue) => {
    const newPosition = Math.floor(newValue);
    audioRef.current.currentTime = newPosition;
    setPosition(newPosition);
  };

  const handlePlaybackRateChange = () => {
    setPlaybackRateAndText(playbackRate[playbackRateAndText.next]);
  };

  const cdnPath =
    podcastSession === ""
      ? `cdn/${episode.PodcastID}/${episode.ID}/${episode.AudioFilename}`
      : `cdn/${episode.PodcastID}/${episode.ID}/${episode.AudioFilename}?podcast_session=${podcastSession}`;

  return (
    <Grid container alignItems="center">
      <audio ref={audioRef} src={getFullCdnUrl(cdnPath)} preload={preload} />
      <Grid item xs={12} sm={9} sx={{ mb: isMobile ? -2 : 0 }}>
        <Box sx={{ width: "100%" }}>
          <Slider
            value={position}
            min={0}
            step={1}
            max={durationInSeconds}
            onChange={handleSliderChange}
            sx={{
              width: "100%",
              "& .MuiSlider-track": {
                height: 4,
              },
              "& .MuiSlider-thumb": {
                width: 12,
                height: 12,
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: -2,
            }}
          >
            <TinyText>{formatDurationFromSecond(position)}</TinyText>
            <TinyText>
              -{formatDurationFromSecond(durationInSeconds - position)}
            </TinyText>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={handleReplay} aria-label="replay 10 seconds">
            <Replay10Icon color="primary" fontSize="large" />
          </IconButton>
          <IconButton aria-label="play/pause" onClick={handlePlayPause}>
            {isPlaying ? (
              <PauseIcon color="primary" fontSize="large" />
            ) : (
              <PlayArrowIcon color="primary" fontSize="large" />
            )}
          </IconButton>
          <IconButton onClick={handleForward} aria-label="forward 30 seconds">
            <Forward30Icon color="primary" fontSize="large" />
          </IconButton>
          <IconButton onClick={handlePlaybackRateChange}>
            <Typography color="primary">{playbackRateAndText.text}</Typography>
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PodcastAudioPlayer;
